<template>
  <FocusTrap>
    <div class="card" id="user_profile_card">
      <div class="card-header">
        <h6 class="card-title">Account Password Change</h6>
      </div>
      <div class="card-body">
        <form action="#">

          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>Username</label>
                <input type="email"  class="form-control" v-model="userprofile.email" disabled autocomplete="off">
              </div>

              <div class="col-lg-6">
                <label>Current password</label>
                <input  ref="txtOldPassword" type="password" value="password" class="form-control" v-model="userprofile.oldpassword" autocomplete="off" autofocus>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>New password</label>
                <input type="password" placeholder="Enter new password" class="form-control" v-model="userprofile.newpassword" autocomplete="off">
              </div>

              <div class="col-lg-6">
                <label>Repeat password</label>
                <input type="password" placeholder="Repeat new password" class="form-control" v-model="userprofile.c_newpassword"  autocomplete="off">
              </div>
            </div>
          </div>

          <div class="text-right">
            <button type="button" class="btn btn-outline-primary" @click="change_password">Change Password</button>
          </div>

        </form>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'UserProfile',
  store,
  data(){
    return {
      userprofile : { "id":0,"name":"", "email":"", "oldpassword":"","newpassword":"", "c_newpassword":"" }
    }
  },
  mounted () {
    const self = this;
    console.log(JSON.stringify(store.state.user));

    self.$data.userprofile.name =store.state.user.name;
    self.$data.userprofile.email =store.state.user.email;

  },
  methods:{
    change_password(){
      const self = this;

      if (self.$data.userprofile.email.trim().length == 0) {
        alert('Invalid User Email');
        return;
      }else if (self.$data.userprofile.oldpassword.trim().length == 0) {
        alert('Invalid Old Password');
        return;
      }else if (self.$data.userprofile.newpassword === self.$data.userprofile.c_newpassword) {
        alert('Password mismatch');
        return;
      }

      $('#user_profile_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.userprofile)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/changepassword`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' , onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.userprofile = JSON.parse('{ "id":0,"name":"", "email":"", "oldpassword":"","newpassword":"", "c_newpassword":"" }');
          self.$emit('profile_saved',resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => {  self.$refs.txtOldPassword.focus(); }, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { self.$refs.txtOldPassword.focus(); }, timer:3000 });
      }).finally(()=>{
        $('#user_profile_card').unblock();
      });


    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
